import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { startOfMonth, endOfMonth } from 'date-fns';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'addEnergyDataInput',
  components: {
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      showValueSetModal: false,
      loader: false,
      editMode: false,
      parent_value_set_id: null,
      project: {
        id: null,
        text: null
      },
      defaultValue: {
        id: null,
        text: null
      },
      remarks: null,
      month: null,
      billingPeriodFrom: null,
      billingPeriodTo: null,
      firstDayOfTheMonth: null,
      lastDayOfTheMonth: null,
      templateId: null,
      requestId: null,
      requestNumId: 0,
      unitType:{
        text:null,
        value:null
      }
    };
  },
  validations: {
    project: {
      text: { required }
    },
    billingPeriodFrom: { required },
    billingPeriodTo: { required },
    remarks: { required }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEnergyDataInput();
        }
      }
    });
  },
  methods: {
    customMonthFunction() {
      if (this.month) {
        this.firstDayOfTheMonth = commonHelper.formattedDate(
          startOfMonth(this.month)
        );
        this.lastDayOfTheMonth = commonHelper.formattedDate(
          endOfMonth(this.month)
        );
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.billingPeriodFrom);
    },
    addEnergyDataInput() {
      const data = {
        fms_prj_id: this.project.id,
        period_from: this.billingPeriodFrom,
        period_to: this.billingPeriodTo,
        remark: this.remarks,
        unit_type:this.unitType.value
      };
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: 0,
          _limit: 10,
          template_name: 'FMS_GENERATE_ENERGY_BATCH_TMP'
        };
        this.$store
          .dispatch('template/getTemplateList', payload)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.templateId = response.data.data.page[0].template_id;
              this.requestId = response.data.data.page[0].request_id;
              if (this.templateId && this.requestId) {
                this.saveSubmitRequest(data);
              } else {
                // alert('Something Went wrong. Please try again');
                this.$bvToast.toast('Something Went wrong. Please try again', {
                  title: 'Alert',
                  variant: 'danger',
                  solid: true
                });
              }
            } else {
              this.loader = false;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    saveSubmitRequest(data) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap',
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK',
            no_of_args: 3,
            request_parameter: JSON.stringify(data),
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: JSON.stringify(data),
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.project = {
          text: item.value_code,
          id: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ENTITY_TYPE_VSET){
        this.unitType ={
          text:item.value_meaning,
          value:item.value_code
        }
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.project = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ENTITY_TYPE_VSET){
        this.unitType.text = null;
        this.unitType.value =null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
